<template>
  <b-table
    :data="data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    icon-pack="icm"
    :mobile-cards="false"
  >
    <b-table-column
      v-slot="props"
      field="Invoice.id"
      label="ReNr"
      numeric
      sortable
    >
      <router-link :to="'/invoices/view/' + props.row.id">{{
        $utilities.padLeftWithNull(props.row.id)
      }}</router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="Invoice.customer_id"
      label="KdNr"
      numeric
      sortable
    >
      {{ $utilities.padLeftWithNull(props.row.customer_id) }}
    </b-table-column>

    <b-table-column v-slot="props" field="Customer.name" label="Kunde" sortable>
      {{ props.row.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="Invoice.ups_number"
      label="UPS-Nr."
      sortable
    >
      <router-link :to="'/tools/upstracking/' + props.row.ups_number">{{
        props.row.ups_number
      }}</router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="UPS-Details"
      sortable
      v-html="getPackageDetails(props.row.ups_packageinfo)"
    >
    </b-table-column>
    <b-table-column v-slot="props" label="UPS-Kosten" sortable numeric
      >{{ getRate(props.row.ups_packageinfo) }} €
    </b-table-column>

    <template #footer>
      <div class="has-text-centered smallgrey">
        Es werden maximal die letzten (nicht stornierten) 100 Sendungen
        angezeigt.
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "upsListing",
  data() {
    return {
      apiControllerName: "ups",
      loading: {},
      data: [],
      error: "",
      isLoading: true
    }
  },

  created: function () {
    this.list()
    this.$store.commit("setHeadline", {
      subheadline: "Paketliste"
    })
  },

  methods: {
    list: async function (data = null) {
      var t = this
      t.isLoading = true
      t.$http
        .get("/" + t.apiControllerName + "/invoiceswithups")
        .then(function (resp) {
          t.data = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
        })
    },
    getPackageDetails: function (d) {
      d = JSON.parse(d)
      //console.log(d)
      var html = []
      if (typeof d.packageinfo != "undefined") {
        var c = 0
        for (var i = 0; i < d.packageinfo.length; i++) {
          c += 1
          html.push(
            "Paket " +
              c +
              " (" +
              d.packageinfo[i].size.qty +
              "er, " +
              d.packageinfo[i].details.weight +
              "kg, Wert:" +
              d.packageinfo[i].details.value +
              " €)"
          )
        }
      }
      //console.log(html)
      return html.join("<br>")
    },
    getRate: function (d) {
      d = JSON.parse(d)
      //console.log(d)
      var html = []
      if (typeof d.rate != "undefined") {
        return d.rate
      }
      return ""
    }
  }
}
</script>
