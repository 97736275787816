<template>
  <div>
    <b-field grouped>
      <b-field class="control has-icons-right">
        <input
          class="input"
          placeholder="UPS-Sendungsnummer"
          v-model="searchphrase"
          v-on:keyup.enter="track()"
        />
        <span class="icon is-right" @click="track()" id="searchbutton">
          <i class="icm icm-search"></i>
        </span>
      </b-field>
    </b-field>
    <div
      class="panel"
      v-if="typeof data.ShipmentIdentificationNumber == 'string'"
    >
      <h2 class="panel-heading is-4">
        <b-icon icon="box"></b-icon>Sendungsdaten für Sendung
        {{ data.ShipmentIdentificationNumber }}
      </h2>

      <div class="columns view-columns p-4">
        <div class="column">
          <b-field label="Versandort">
            {{ data.Shipper.Address.CountryCode }}-{{
              data.Shipper.Address.PostalCode
            }}
            {{ data.Shipper.Address.City }}
          </b-field>
        </div>
        <div class="column">
          <b-field label="Zielort">
            {{ data.ShipTo.Address.CountryCode }}-{{
              data.ShipTo.Address.PostalCode
            }}
            {{ data.ShipTo.Address.City }}
          </b-field>
        </div>
        <div class="column">
          <b-field label="Abgeholt">
            {{ formatDate(data.PickupDate) }}
          </b-field>
        </div>

        <div class="column">
          <b-field label="Gewicht">
            <span v-if="typeof data.Package.PackageWeight != 'undefined'">
              {{ data.Package.PackageWeight.Weight }}
              {{ data.Package.PackageWeight.UnitOfMeasurement.Code }}
            </span>
          </b-field>
        </div>
      </div>
      <div class="columns view-columns p-4 is-multiline">
        <div class="column is-full" v-if="data.Package instanceof Object">
          <b-table
            :data="data.Package.Activity"
            :striped="true"
            :hoverable="true"
            :mobile-cards="false"
            is-fullwidth
            style="width: 100%"
          >
            <b-table-column v-slot="props" label="Zeitpunkt" numeric sortable>
              {{ formatDate(props.row.Date) }}
              {{ formatTime(props.row.Time) }}
            </b-table-column>

            <b-table-column v-slot="props" label="Ort">
              {{ props.row.ActivityLocation.Address.CountryCode }}-
              {{ props.row.ActivityLocation.Address.Postalcode }}
              {{ props.row.ActivityLocation.Address.City }}
            </b-table-column>

            <b-table-column v-slot="props" field="descr" label="Vorgang">
              {{ props.row.Status.StatusType.Description }}
            </b-table-column>
          </b-table>
        </div>
        <div class="column is-full" v-if="data.Package instanceof Array">
          <div v-for="(pkg, k) in data.Package" :key="k">
            <b-table
              :data="pkg.Activity"
              :striped="true"
              :hoverable="true"
              :mobile-cards="false"
              is-fullwidth
              style="width: 100%"
            >
              <b-table-column v-slot="props" label="Zeitpunkt" numeric sortable>
                {{ formatDate(props.row.Date) }}
                {{ formatTime(props.row.Time) }}
              </b-table-column>

              <b-table-column v-slot="props" label="Ort">
                {{ props.row.ActivityLocation.Address.CountryCode }}-
                {{ props.row.ActivityLocation.Address.Postalcode }}
                {{ props.row.ActivityLocation.Address.City }}
              </b-table-column>

              <b-table-column v-slot="props" field="descr" label="Vorgang">
                {{ props.row.Status.StatusType.Description }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
export default {
  name: "upsTracking",
  data() {
    return {
      searchphrase: "",
      data: {},
      error: "",
      subtitle: "",
      isLoading: false
    }
  },

  created: function () {
    if (
      typeof this.$route.params.id != "undefined" &&
      this.$route.params.id.length > 10
    ) {
      this.searchphrase = this.$route.params.id
      this.track()
    }
    this.$store.commit("setHeadline", {
      subheadline: "Pakettracking"
    })
  },

  methods: {
    track: async function () {
      var t = this
      t.isLoading = true
      t.$http
        .post("/ups/tracking3", {
          id: t.searchphrase
        })
        .then(function (resp) {
          t.data = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
        })
    },
    formatDate: function (d) {
      return (
        d.substring(6, 8) + "." + d.substring(4, 6) + "." + d.substring(0, 4)
      )
    },
    formatTime: function (t) {
      return t.substring(0, 2) + ":" + t.substring(2, 4)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
